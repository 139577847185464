import React from 'react'
import styled from 'styled-components'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'

import hex from 'src/assets/svg/about-hexagon-bg.svg'

import MainWrapper from 'src/components/global/MainWrapper.js'
const StyledWrapper = styled(MainWrapper)`
  margin: 80rem 0;

  @media (max-width: ${mediaQuery.laptop}) {
    background-image: url(${hex});
    background-size: cover;
    background-position: center;
  }
`

const Wrapper = styled.section`
  width: 83.33%;
  margin: 0 auto;
  padding: 0 8.33%;
  padding-top: 20rem;
  padding-bottom: 20rem;
  background-image: url(${hex});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;

    padding: 0%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    background-image: none;
  }
`
const Small = styled.span`
  color: ${colors.darkGreen};
  font-size: ${typographySizes.s}rem;
`
const Title = styled.h2`
  color: ${colors.dark};
  font-size: ${typographySizes.xl}rem;
  line-height: 1;
  margin-top: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
  }
`

const SubTitle = styled.h3`
  font-size: ${typographySizes.l}rem;
  line-height: 1;
  margin-top: 10rem;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`

const Paragraph = styled.p`
  font-size: ${typographySizes.s}rem;
  font-family: 'Poppins', sans-serif;
  column-count: 2;
  column-gap: 30rem;
  margin-top: 50rem;
  width: 80%;
  margin-left: 10%;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: -30rem;
    top: 10rem;
    transform: translateX(-100%);
    height: 1rem;
    width: calc(12.5% - 30rem);
    background-color: ${colors.darkGreen};
  }

  @media (max-width: ${mediaQuery.tablet}) {
    column-count: 1;
    margin-top: 20rem;
    width: 80%;
    ::before {
      content: '';
      position: absolute;
      left: -10rem;
      top: 10rem;
      transform: translateX(-100%);
      height: 1rem;
      width: calc(12.5% - 10rem);
    }
  }
`

const Label = styled.span`
  display: inline-block;

  margin-bottom: 10rem;

  margin-top: 50rem;
  align-self: flex-end;
  span {
    font-size: 23rem;
    display: inline-block;
    color: ${colors.white};
    line-height: 50rem;
    background-color: ${colors.darkGreen};
    transform: rotate(-2deg);
    padding: 0 15rem;
    text-align: center;
    height: 50rem;
    border-radius: 9rem;
  }
`

const AboutSection = props => (
  <StyledWrapper id="o-nas">
    <Wrapper>
      <Small
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        krótko o Kimidori
      </Small>
      <Title
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        Moda,trend?
      </Title>
      <SubTitle
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        Dla nas to sposób na życie
      </SubTitle>
      <Paragraph
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        Kimidori to pasjonaci wegańskiego sushi, którzy teraz skupiają się
        wyłącznie na dostawie. Choć nasza restauracja na Powiślu zakończyła
        działalność stacjonarną, nadal tworzymy wyjątkowe, roślinne sushi w
        koncepcji dark kitchen. Teraz możesz zamawiać nasze dania online i
        cieszyć się nimi tam, gdzie lubisz – w domu, w pracy, na spotkaniach z
        przyjaciółmi. Nowa forma, ta sama jakość. Zamów i delektuj się smakiem
        Kimidori.
      </Paragraph>

      <Label
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        <span>JESTEŚ GREEN?</span>
      </Label>
    </Wrapper>
  </StyledWrapper>
)

export default AboutSection
